import React, {
    Component
} from "react";

export default function Logo(props) {

    return ( 
        <svg 
        width = {props.style.width}
        height = {props.style.height}
        viewBox = "0 0 743 272"
        fill = "none"
        xmlns = "http://www.w3.org/2000/svg" >
            <path d = "M742.896 135.63C742.896 210.537 679.973 271.26 602.352 271.26C524.731 271.26 461.808 210.537 461.808 135.63C461.808 60.7235 524.731 2.70605e-05 602.352 2.70605e-05C679.973 2.70605e-05 742.896 60.7235 742.896 135.63Z"
            fill = "#99CC33" / >
            <path d = "M424.376 224.592C426.014 222.683 426.835 220.021 426.835 216.607C426.835 213.191 426.014 210.544 424.376 208.668C422.736 206.795 420.639 205.855 418.081 205.855C415.521 205.855 413.422 206.795 411.783 208.668C410.143 210.544 409.323 213.191 409.323 216.607C409.323 220.021 410.143 222.683 411.783 224.592C413.422 226.501 415.521 227.456 418.081 227.456C420.639 227.456 422.736 226.501 424.376 224.592V224.592ZM448.676 169.587V244.133H427.524V239.612C424.31 243.296 419.391 245.138 412.767 245.138C408.046 245.138 403.699 243.982 399.733 241.672C395.764 239.361 392.614 236.031 390.287 231.677C387.959 227.322 386.796 222.299 386.796 216.607C386.796 210.914 387.959 205.905 390.287 201.587C392.614 197.265 395.764 193.95 399.733 191.639C403.699 189.33 408.046 188.173 412.767 188.173C418.734 188.173 423.294 189.749 426.443 192.895V169.587H448.676Z"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"} 
            />
            <path d = "M357.182 224.592C358.821 222.683 359.642 220.021 359.642 216.607C359.642 213.191 358.821 210.544 357.182 208.668C355.542 206.795 353.444 205.855 350.887 205.855C348.328 205.855 346.228 206.795 344.589 208.668C342.949 210.544 342.13 213.191 342.13 216.607C342.13 220.021 342.949 222.683 344.589 224.592C346.228 226.501 348.328 227.456 350.887 227.456C353.444 227.456 355.542 226.501 357.182 224.592V224.592ZM334.8 241.471C330.044 239.028 326.323 235.644 323.634 231.324C320.944 227.005 319.6 222.099 319.6 216.607C319.6 211.114 320.944 206.207 323.634 201.888C326.323 197.567 330.044 194.201 334.8 191.791C339.555 189.379 344.917 188.173 350.887 188.173C356.919 188.173 362.313 189.379 367.069 191.791C371.824 194.201 375.53 197.567 378.186 201.888C380.843 206.207 382.171 211.114 382.171 216.607C382.171 222.099 380.843 227.005 378.186 231.324C375.53 235.644 371.824 239.028 367.069 241.471C362.313 243.916 356.919 245.138 350.887 245.138C344.917 245.138 339.555 243.916 334.8 241.471"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
            <path d = "M290.481 224.592C292.12 222.683 292.941 220.021 292.941 216.607C292.941 213.191 292.12 210.544 290.481 208.668C288.841 206.795 286.743 205.855 284.186 205.855C281.627 205.855 279.527 206.795 277.889 208.668C276.248 210.544 275.429 213.191 275.429 216.607C275.429 220.021 276.248 222.683 277.889 224.592C279.527 226.501 281.627 227.456 284.186 227.456C286.743 227.456 288.841 226.501 290.481 224.592V224.592ZM268.1 241.471C263.344 239.028 259.622 235.644 256.933 231.324C254.243 227.005 252.9 222.099 252.9 216.607C252.9 211.114 254.243 206.207 256.933 201.888C259.622 197.567 263.344 194.201 268.1 191.791C272.854 189.379 278.216 188.173 284.186 188.173C290.219 188.173 295.613 189.379 300.369 191.791C305.123 194.201 308.829 197.567 311.486 201.888C314.142 206.207 315.47 211.114 315.47 216.607C315.47 222.099 314.142 227.005 311.486 231.324C308.829 235.644 305.123 239.028 300.369 241.471C295.613 243.916 290.219 245.138 284.186 245.138C278.216 245.138 272.854 243.916 268.1 241.471"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
            <path d = "M226.337 207.061H246.506V236.799C242.437 239.681 237.716 241.891 232.339 243.43C226.959 244.97 221.68 245.74 216.499 245.74C209.152 245.74 202.562 244.183 196.725 241.069C190.885 237.954 186.312 233.601 183.001 228.008C179.688 222.416 178.032 216.07 178.032 208.97C178.032 201.871 179.688 195.524 183.001 189.931C186.312 184.34 190.936 179.985 196.871 176.871C202.808 173.756 209.513 172.199 216.991 172.199C223.744 172.199 229.796 173.354 235.143 175.665C240.486 177.976 244.93 181.341 248.473 185.762L233.618 199.124C229.289 193.967 224.139 191.388 218.17 191.388C213.056 191.388 208.987 192.962 205.973 196.11C202.954 199.259 201.447 203.544 201.447 208.97C201.447 214.263 202.938 218.514 205.923 221.729C208.907 224.944 212.892 226.551 217.875 226.551C220.828 226.551 223.647 225.984 226.337 224.843V207.061Z"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
            <path d = "M86.4426 169.586H108.677V244.132H86.4426V169.586Z"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
            <path d = "M118.513 169.586H140.748V244.132H118.513V169.586Z"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
            <path d = "M48.3695 214.796L41.6796 197.116L34.9883 214.796H48.3695ZM54.7642 231.876H28.5949L23.971 244.133H0.359619L30.4642 173.806H53.2885L83.3931 244.133H59.3881L54.7642 231.876Z"
            fill = {(props.theme == 'dark') ? "#fff" : "#6F6E6C"}
            />
        </svg>
    )
}